import React, { ReactNode, MouseEventHandler, useState, Dispatch, SetStateAction, PropsWithChildren } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useDrag } from 'react-dnd'
import useReorderHoverDrop, { DIRECTION_VERTICAL } from '../components/use_reorder_hover_drop'
import { attendedStates, stateChanged } from '../states'
import {
  PlannedSessionState,
  TrainingSessionState,
  PlannedSession,
  TrainingPlanContext,
  Unavailabilities,
  SelectedPlanCells,
  UsersDependencies,
  UserFilter,
  User,
  Learner,
  PlannedSessionUserData,
  SetSelectedCellFunction
} from '../types'

import { DEFAULT_MODULE_ESTIMATED_MINUTES } from '../constants'
import PlannedSessionVisibilityControls from './planned_session_visibility_controls'
import {
  formatUnavailabilityText,
  getModuleIssueContent,
  hasChangeRequestConflict,
  hiddenState,
  toFriendlyName
} from './session_editor_helpers'
import { getModulesFromKeys } from '../utils'
import getDisplaySession, { sessionsIsPartial } from './session_status'
import { longDateFormat } from '../../utils/date_helpers'
import SessionStatusInfo from './session_status_info'
import CellEditLink from './cell_edit_link'
import { assertUnreachable } from '../../utils/asserts'
import ChangeRequestConflictModal from './change_request_conflict_modal'
import { ControlledMenu, MenuItem, useMenuState } from '@szhsin/react-menu'
import TableMenu from './table_menu'
import ViewInOtherPlan from './view_in_other_plan'
import {
  SessionDataForModule,
  SharedNotes,
  SharedNotesForModule,
  TrainingSessionStatus,
  UnavailabilityType
} from '../../generated_types/training_plan'
import LearnerModuleAccessModal from '../../learner_module_access_editor/learner_module_access_modal'
import { LearnerModuleAccessData } from '../../generated_types/learner_module_accesses'
import DoNotScheduleModal from './do_not_schedule_modal'
import DoNotScheduleStatusButton from './do_not_schedule_status_button'

const DND_ITEM_TYPE = 'PlannedSession'

export type UpdateDoNotScheduleFunction = (args: {
  userId: number
  reason: string
  moduleKey: string
  doNotSchedule: boolean
}) => void
export type PlannedSessionRowProps = {
  session: PlannedSession
  learnerUnavailability: Unavailabilities
  usersDependencies?: UsersDependencies
  selectedCells: SelectedPlanCells
  sessionIndex: number
  trainingPlanContext: TrainingPlanContext
  indefinitePauses: UserFilter
  completeUsers?: UserFilter
  changeRequestIdsToReject: number[]
  setChangeRequestIdsToReject: Dispatch<SetStateAction<number[]>>
  cellClicked: (args: { groupIndex: number; userIndexInGroup: number; sessionIndex: number }) => void
  updateDoNotSchedule: UpdateDoNotScheduleFunction
  toggleSessionVisibility: (sessionIndex: number) => void
  toggleSessionDeletion: (sessionIndex: number) => void
  updateOrdering: (fromIndex: number, toIndex: number) => void
  startEditing: (session: PlannedSession) => void
  editable: boolean
  scheduler: boolean
  learnerGroups: Learner[][]
  sharedNotesForModule?: SharedNotesForModule
  setSharedNotes: Dispatch<SetStateAction<SharedNotes>>
  sessionsForModule?: SessionDataForModule
  setDisplayEditCellModalFor: SetSelectedCellFunction
  setDisplaySessionInfoModalFor: SetSelectedCellFunction
}

const PlannedSessionRow = React.memo<PlannedSessionRowProps>(
  ({
    session,
    learnerUnavailability,
    usersDependencies,
    selectedCells,
    sessionIndex,
    trainingPlanContext,
    indefinitePauses,
    completeUsers,
    changeRequestIdsToReject,
    setChangeRequestIdsToReject,
    cellClicked,
    toggleSessionVisibility,
    toggleSessionDeletion,
    updateOrdering,
    startEditing,
    editable,
    scheduler,
    learnerGroups,
    sharedNotesForModule,
    setSharedNotes,
    sessionsForModule,
    updateDoNotSchedule,
    setDisplayEditCellModalFor,
    setDisplaySessionInfoModalFor
  }) => {
    const dropRef = React.useRef(null)
    const dragRef = React.useRef(null)

    // The currently active change request conflict model, by user id
    const [requestConflictModalUserId, setRequestConflictModalUserId] = useState<number>()

    const isEditorView = editable && !scheduler

    const handleEditClick: React.MouseEventHandler = React.useCallback(
      e => {
        startEditing(session)
        e.stopPropagation()
      },
      [startEditing, session]
    )

    const { modules, available_learners } = trainingPlanContext

    const mod = modules[session.training_module]

    const sessionTitle = mod ? mod.title : toFriendlyName(session.training_module)
    const sessionDuration = mod ? mod.estimated_minutes : DEFAULT_MODULE_ESTIMATED_MINUTES
    const sessionDependencies = mod ? mod.module_dependencies : []

    const confirmFollowup: MouseEventHandler = e => {
      const response = confirm('Schedule follow up session?')
      if (!response) {
        e.preventDefault()
      }
    }

    const totalUserDependencies: { [userId: string]: number } = {}

    const updateSharedNotes = (a: LearnerModuleAccessData) => {
      setSharedNotes(current => {
        const forModule = current[a.module_key] || {}
        const updated: SharedNotes = {
          ...current,
          [a.module_key]: { ...forModule, [a.user_id]: { no_session_needed: a.no_session_needed } }
        }
        return updated
      })
    }
    const attendances = learnerGroups.flatMap((learners, groupIndex) => {
      const attendancesForGroup = learners.map(({ user_id: userId }, userIndexInGroup) => {
        const user: User = available_learners[userId.toString()]
        if (!user) return null // This might happen for e.g. deprecated users in a plan
        // a user may not have an attendance, so userData may be undefined
        const userData = session.attendances[userId.toString()] as (typeof session)['attendances'][0] | undefined
        const cellClasses = ['status', 'sw-tooltip', 'tooltip-left']
        const sharedNotesForUser = sharedNotesForModule ? sharedNotesForModule[user.id] : undefined

        let sessionStatusClasses: string[] = []
        let cellContent: ReactNode = null
        let cellTooltipStatus = ''
        let sessionNotesIndicator: ReactNode = null // for when notes shared, but a session is still required
        /**
         * Cell Effect (Indefinitely Paused User)
         */
        if (indefinitePauses.userIds.includes(userId)) {
          if (indefinitePauses.show) cellClasses.push('indefinitely-paused')
          else return null
        }

        if (completeUsers && !completeUsers.show && completeUsers.userIds.includes(userId)) {
          return null
        }

        const sessionStatuses = sessionsForModule?.[userId]
        const trainingSessionStatus = getDisplaySession(sessionStatuses)
        let doNotSchedule = false

        if (userData) {
          const { attendance_requirement } = userData
          doNotSchedule = !!userData.do_not_schedule
          /**
           * Cell Background (Suggested Attendance)
           */
          if (doNotSchedule) {
            cellClasses.push('do-not-schedule')
            cellTooltipStatus = 'Do Not Schedule'
          } else if (attendance_requirement === PlannedSessionState.Optional) {
            cellClasses.push('optional')
            cellTooltipStatus = 'Optional'
          } else if (attendance_requirement === PlannedSessionState.Mandatory) {
            cellClasses.push('mandatory')
            cellTooltipStatus = 'Required'
          }
        }
        /**
         * Cell Icon (Scheduling Status)
         */

        const doNotScheduleButton = doNotSchedule && (
          <DoNotScheduleStatusButton
            openModal={() => setDisplaySessionInfoModalFor({ userId: user.id, moduleKey: session.training_module })}
          />
        )
        switch (trainingSessionStatus?.status) {
          case TrainingSessionState.ScheduledDeclined:
            if (doNotSchedule) {
              cellContent = doNotScheduleButton
              break
            }
          // falls through
          case TrainingSessionState.Scheduled:
          case TrainingSessionState.ScheduledAccepted: {
            const scheduledDate = new Date(trainingSessionStatus.start)
            let contents: ReactNode = '🗓️'

            sessionStatusClasses.push('scheduled')
            if (sessionsIsPartial(sessionStatuses)) sessionStatusClasses.push('partially-attended')
            cellTooltipStatus = `Scheduled ${longDateFormat.format(scheduledDate)}`

            if (trainingSessionStatus.status === TrainingSessionState.ScheduledAccepted) {
              cellTooltipStatus += '\n(Accepted)'
              contents = (
                <span className="relative">
                  🗓<span className="overlay-icon">✔️</span>
                </span>
              )
            } else if (trainingSessionStatus.status === TrainingSessionState.ScheduledDeclined) {
              cellTooltipStatus += '\n(Declined)'
              contents = (
                <span className="relative">
                  🗓<span className="overlay-icon">❌</span>
                </span>
              )
            }

            cellContent = (
              <CellEditLink editable={editable} sessionCode={trainingSessionStatus.code}>
                {contents}
              </CellEditLink>
            )
            break
          }
          case TrainingSessionState.Complete: {
            const completedDate = new Date(trainingSessionStatus.start)

            sessionStatusClasses.push('attended')
            cellTooltipStatus = `Completed ${longDateFormat.format(completedDate)}`
            cellContent = (
              <CellEditLink editable={editable} sessionCode={trainingSessionStatus.code}>
                ✅
              </CellEditLink>
            )
            break
          }
          case TrainingSessionState.Unconfirmed: {
            sessionStatusClasses.push('unconfirmed')
            cellTooltipStatus = 'Unconfirmed'
            cellContent = (
              <CellEditLink editable={editable} sessionCode={trainingSessionStatus.code}>
                ❓
              </CellEditLink>
            )
            break
          }
          case TrainingSessionState.Partial: {
            if (doNotSchedule) {
              cellContent = doNotScheduleButton
              break
            }
            sessionStatusClasses.push('partially-attended')
            cellTooltipStatus = 'Partially Complete'
            if (editable) {
              cellContent = (
                <span className="sw-tooltip tooltip-top" data-tooltip="Schedule Follow-Up">
                  <a
                    className="sw-btn btn-icon cell-link"
                    href={`/coaching_sessions/${trainingSessionStatus.code}/schedule_followup`}
                    target="blank"
                    onClick={confirmFollowup}
                  >
                    <FontAwesomeIcon icon={['fas', 'copy']} />
                  </a>
                </span>
              )
            }
            break
          }
          case TrainingSessionState.Declined:
          case TrainingSessionState.Absent: {
            if (doNotSchedule) {
              cellContent = doNotScheduleButton
              break
            }
            const description = trainingSessionStatus.status == TrainingSessionState.Declined ? 'Declined' : 'Absent'
            const sessionDate = new Date(trainingSessionStatus.start)
            cellTooltipStatus += `\n(${description} ${longDateFormat.format(sessionDate)})`
            cellContent = (
              <CellEditLink editable={editable} sessionCode={trainingSessionStatus.code}>
                ❌
              </CellEditLink>
            )
            break
          }
          default:
            if (trainingSessionStatus) assertUnreachable(trainingSessionStatus.status)
            if (doNotSchedule) {
              cellContent = doNotScheduleButton
            }
            break
        }

        if (sharedNotesForUser) {
          sessionNotesIndicator = (
            <div
              className="absolute bottom-1 left-2 sw-tooltip tooltip-right hide-cell-tooltip"
              data-tooltip="Module notes shared"
            >
              📗
            </div>
          )
          if (sharedNotesForUser.no_session_needed) {
            switch (trainingSessionStatus?.status) {
              case TrainingSessionState.Scheduled:
              case TrainingSessionState.ScheduledAccepted:
              case TrainingSessionState.ScheduledDeclined:
              case TrainingSessionState.Complete:
              case TrainingSessionState.Unconfirmed:
                /* these states take prioriity over the shared notes no session needed status */
                break

              case TrainingSessionState.Declined:
              case TrainingSessionState.Absent:
              case TrainingSessionState.Partial:
              case undefined:
                // session notes are the primary status: display in main area instead of session notes
                sessionNotesIndicator = null
                cellTooltipStatus = 'Module notes shared.\nNo session needed'
                sessionStatusClasses = ['attended']

                cellContent = <span className="text-xl">📗</span>
                break
              default:
                if (trainingSessionStatus) assertUnreachable(trainingSessionStatus.status)
                break
            }
          }
        }

        /**
         * Scheduler View
         */
        if (scheduler && editable && userData && !doNotSchedule && cellContent === null) {
          let dependencyContent: ReactNode = null
          let unavailabilityContent: ReactNode = null

          if (sessionDependencies.length > 0) {
            const userDependencies = usersDependencies && usersDependencies[userId] ? usersDependencies[userId] : []
            const dependentModules = getModulesFromKeys(userDependencies, modules)
            if (dependentModules.length > 0) {
              cellClasses.push('dependencies')
              dependencyContent = (
                <div
                  className="sw-tooltip tooltip-bottom"
                  data-tooltip={`Dependencies:\n${dependentModules.map(dependency => dependency.title).join('\n')}`}
                >
                  {userDependencies.length}
                </div>
              )
              totalUserDependencies[userId] = dependentModules.length
            }
          }

          if (learnerUnavailability[userId]) {
            cellClasses.push('unavailable')

            let icon: IconProp

            //pick an icon depending on the unavailabity types we have. in order of (decreasing) precedence these are
            // session > pause (including company pause) > working hours
            const unavailabilityTypes = learnerUnavailability[userId].map(u => u.type)
            if (unavailabilityTypes.includes(UnavailabilityType.Session)) {
              icon = ['far', 'calendar']
            } else if (
              unavailabilityTypes.includes(UnavailabilityType.Snooze) ||
              unavailabilityTypes.includes(UnavailabilityType.CompanyPause)
            ) {
              icon = ['fas', 'pause']
            } else {
              icon = ['far', 'clock']
            }

            unavailabilityContent = (
              <div
                className="sw-tooltip tooltip-bottom"
                data-tooltip={`Time blocked:\n${learnerUnavailability[userId]
                  .map(unavailability => formatUnavailabilityText(unavailability))
                  .join('\n')}`}
              >
                <FontAwesomeIcon icon={icon} />
              </div>
            )
          }

          cellContent = (
            <>
              {dependencyContent}
              {unavailabilityContent}
            </>
          )
        }

        if (trainingPlanContext.newAddedUserIds.indexOf(userId) >= 0 && !userData) {
          cellClasses.push('new-user')
        }

        if (trainingPlanContext.userIdsToRemove.has(userId)) {
          cellClasses.push('marked-for-destruction')
        }

        if (!session.dbState || stateChanged(userData, session.dbState?.attendances[userId])) {
          cellClasses.push('changed')
        }

        if (
          scheduler &&
          editable &&
          selectedCells.modules.find(module => module.key === session.training_module) &&
          selectedCells.users[userId]
        ) {
          cellClasses.push('selected')
        }

        let handleCellClick: MouseEventHandler | undefined
        if (isEditorView)
          handleCellClick = () => {
            if (hasChangeRequestConflict(userData, changeRequestIdsToReject, sessionStatuses))
              setRequestConflictModalUserId(userId)
            else cellClicked({ groupIndex, userIndexInGroup, sessionIndex })
          }
        else if (editable || scheduler)
          handleCellClick = async () => cellClicked({ groupIndex, userIndexInGroup, sessionIndex })

        const tooltipContent = `${sessionTitle}\n${user.name}\n${cellTooltipStatus}`

        return (
          <AttendanceCell
            id={`cell-${session.id}-${userId}`}
            key={userId}
            className={cellClasses.concat(sessionStatusClasses).join(' ')}
            onClick={handleCellClick}
            tooltip={tooltipContent}
            editable={editable}
            user={user}
            moduleKey={session.training_module}
            userData={userData}
            sharedNotesData={sharedNotesForModule?.[userId]}
            updateSharedNotes={updateSharedNotes}
            scheduler={scheduler}
            sessionStatus={trainingSessionStatus}
            sessionTitle={sessionTitle}
            updateDoNotSchedule={updateDoNotSchedule}
            setDisplayEditCellModalFor={setDisplayEditCellModalFor}
          >
            {cellContent}
            <SessionStatusInfo
              userId={user.id}
              moduleKey={session.training_module}
              trainingSessionStatus={trainingSessionStatus}
              sessionStatuses={sessionStatuses}
              editable={editable}
              sessionUserData={userData}
              setDisplaySessionInfoModalFor={setDisplaySessionInfoModalFor}
            />
            {sessionNotesIndicator}
            {isEditorView && userData?.change_requests && requestConflictModalUserId === userId && (
              <ChangeRequestConflictModal
                changeRequests={userData.change_requests}
                onDeleteRequest={(requestId, event) => {
                  event.stopPropagation()
                  setRequestConflictModalUserId(undefined)
                  setChangeRequestIdsToReject(existingIds => [...existingIds, requestId])
                  cellClicked({ groupIndex, userIndexInGroup, sessionIndex })
                }}
                onCancelDelete={event => {
                  event.stopPropagation()
                  setRequestConflictModalUserId(undefined)
                }}
              />
            )}
          </AttendanceCell>
        )
      })
      if (groupIndex < learnerGroups.length - 1) {
        attendancesForGroup.push(<td key={`divider-${groupIndex}`} className="px-2 border-none" />)
      }
      return attendancesForGroup
    })

    const [, drop] = useReorderHoverDrop({
      // When the edit controls are dropped from the DOM, DnD stops working
      // This conditional forces the react-dnd hooks to refresh with new refs to the drag handle
      itemType: isEditorView ? DND_ITEM_TYPE : 'noop',
      direction: DIRECTION_VERTICAL,
      hoverIndex: sessionIndex,
      updateOrdering,
      dropRef: dropRef
    })

    const [{ isDragging }, drag, preview] = useDrag({
      type: isEditorView ? DND_ITEM_TYPE : 'noop',
      item: { index: sessionIndex },
      collect: monitor => ({
        isDragging: monitor.isDragging()
      })
    })

    const opacity = isDragging ? 0 : 1

    preview(drop(dropRef))
    drag(dragRef)

    const rowClasses: Array<string> = []
    // the will destroy state always takes priority
    if (session.markedForDestruction) {
      rowClasses.push('marked-for-destruction')
    } else {
      if (hiddenState(session.status) && !hiddenState(session.dbState?.status)) {
        rowClasses.push('marked-for-hiding')
      } else if (!hiddenState(session.status) && hiddenState(session.dbState?.status)) {
        rowClasses.push('marked-for-unhiding')
      } else if (hiddenState(session.status)) {
        rowClasses.push('hidden-session')
      }
    }

    if (session.reordered && session.dbState) {
      rowClasses.push('reordered')
    }

    if (session.dbState && session.training_module != session.dbState.training_module) {
      rowClasses.push('module-changed')
    }

    let editControls: ReactNode = null
    if (isEditorView) {
      const moduleKeyEditable =
        sessionsForModule === undefined || Object.values(sessionsForModule).every(sessions => !sessions.length)

      editControls = (
        <>
          <div ref={dragRef} className="drag-handle">
            <FontAwesomeIcon icon={['fas', 'grip-vertical']} size="xl" className="text-midgrey" />
          </div>
          <PlannedSessionVisibilityControls
            plannedSession={session}
            toggleSessionDeletion={toggleSessionDeletion}
            toggleSessionVisibility={toggleSessionVisibility}
            sessionIndex={sessionIndex}
          />
          {moduleKeyEditable && (
            <button
              onClick={handleEditClick}
              className="sw-btn btn-icon w-9 h-9 sw-tooltip tooltip-top"
              data-tooltip="Edit Key"
              aria-label="Edit Key"
            >
              <FontAwesomeIcon icon={['fas', 'pencil']} />
            </button>
          )}
        </>
      )
    }

    let sessionTitleTooltipContent = `${sessionTitle}`
    if (editable) {
      // don't show module keys to managers
      sessionTitleTooltipContent += `\n${session.training_module}`
    }

    if (scheduler) {
      const dependentModules = getModulesFromKeys(sessionDependencies, modules)
      sessionTitleTooltipContent +=
        dependentModules.length > 0
          ? '\n- Dependencies:\n  ' + dependentModules.map(module => module.title).join('\n  ')
          : '\n- No Dependencies'
    }

    const { moduleTooltip, moduleIcon } = getModuleIssueContent(mod)
    const moduleIssuesIcon =
      editable && moduleTooltip && moduleIcon ? (
        <span className="sw-tooltip tooltip-left" style={{ cursor: 'default' }} data-tooltip={moduleTooltip}>
          {moduleIcon}
        </span>
      ) : null

    const [menuProps, toggleMenu] = useMenuState()
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 })
    const showContextMenu: MouseEventHandler = e => {
      e.preventDefault()
      setAnchorPoint({ x: e.clientX, y: e.clientY })
      toggleMenu(true)
    }

    const menu = trainingPlanContext.trainingPlanId && editable && (
      <ControlledMenu
        {...menuProps}
        anchorPoint={anchorPoint}
        onClose={() => toggleMenu(false)}
        menuClassName="font-semibold"
      >
        <MenuItem>
          <ViewInOtherPlan trainingPlanId={trainingPlanContext.trainingPlanId} moduleKey={session.training_module} />
        </MenuItem>
      </ControlledMenu>
    )

    return (
      <tr ref={dropRef} style={{ opacity }} className={rowClasses.join(' ')}>
        <td
          onContextMenu={showContextMenu}
          className={`controls sw-tooltip tooltip-right${editable ? '' : ' md:border-r-2'}`}
          data-tooltip={sessionTitleTooltipContent}
        >
          {editControls}
          {/**
           * RTL ellipsis trick
           * See  https://github.com/skiller-whale/train/blob/main/app/javascript/components/attendee_controls/recent_edit_list.jsx#L32-L39
           */}
          <div className="module-name text-ellipsis">&lrm;{sessionTitle}</div>
          {moduleIssuesIcon}
          <TableMenu>{menu}</TableMenu>
        </td>
        {editable ? <td className="estimate-mins">{sessionDuration}</td> : null}
        {attendances}
      </tr>
    )
  }
)

PlannedSessionRow.displayName = 'PlannedSession'

type AttendanceCellProps = PropsWithChildren<{
  id: string
  className: string
  onClick?: MouseEventHandler
  tooltip: string
  editable: boolean
  user: User
  userData: PlannedSessionUserData | undefined
  sharedNotesData: SharedNotesForModule[number] | undefined
  sessionStatus: TrainingSessionStatus | undefined
  moduleKey: string
  updateSharedNotes: (d: LearnerModuleAccessData) => void
  scheduler: boolean
  sessionTitle: string
  updateDoNotSchedule: UpdateDoNotScheduleFunction
  setDisplayEditCellModalFor: SetSelectedCellFunction
}>

const AttendanceCell = ({
  id,
  className,
  onClick,
  tooltip,
  editable,
  sharedNotesData,
  user,
  updateSharedNotes,
  moduleKey,
  scheduler,
  userData,
  sessionStatus,
  sessionTitle,
  updateDoNotSchedule,
  setDisplayEditCellModalFor,
  children
}: AttendanceCellProps) => {
  const [shareNotesModalOpen, setShareNotesModalOpen] = useState(false)
  const [doNotScheduleModalOpen, setDoNotScheduleModalOpen] = useState(false)
  const [menuProps, toggleMenu] = useMenuState()
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 })
  const showContextMenu: MouseEventHandler = e => {
    e.preventDefault()
    setAnchorPoint({ x: e.clientX, y: e.clientY })
    toggleMenu(true)
  }

  const menuItems: ReactNode[] = []

  if (!sharedNotesData) {
    const enabled = scheduler
    menuItems.push(
      <MenuItem
        key="share-notes"
        disabled={!enabled}
        onClick={() => {
          setShareNotesModalOpen(true)
        }}
      >
        <span
          className={enabled ? '' : 'sw-tooltip'}
          data-tooltip={enabled ? undefined : 'Notes can be shared from the scheduler view'}
        >
          Share Session Notes
        </span>
      </MenuItem>
    )
  }

  // This is an edit action, so don't enable in the scheduler. In addition,
  // you can't "do not schedule" a cell if
  // - it's already been done
  // - it was a grey cell to begin with
  // - there is a complete, scheduled but not declined, or unconfirmed session
  const doNotScheduleDisabledStatuses: (TrainingSessionState | undefined)[] = [
    TrainingSessionState.Complete,
    TrainingSessionState.Scheduled,
    TrainingSessionState.ScheduledAccepted,
    TrainingSessionState.Unconfirmed
  ]

  const showDoNotSchedule =
    userData &&
    userData.attendance_requirement !== PlannedSessionState.None &&
    !userData.do_not_schedule &&
    !doNotScheduleDisabledStatuses.includes(sessionStatus?.status)

  if (showDoNotSchedule) {
    const enabled = !scheduler
    menuItems.push(
      <MenuItem disabled={!enabled} onClick={() => setDoNotScheduleModalOpen(true)} key="do-not-schedule">
        <span
          className={enabled ? '' : 'sw-tooltip'}
          data-tooltip={enabled ? undefined : 'Scheduling can be skipped from the editor view'}
        >
          Do Not Schedule
        </span>
      </MenuItem>
    )
  }

  if (userData?.do_not_schedule) {
    const enabled = !scheduler
    const reEnableScheduling = () => {
      updateDoNotSchedule({
        userId: user.id,
        moduleKey,
        doNotSchedule: false,
        reason: ''
      })
    }

    menuItems.push(
      <MenuItem disabled={!enabled} onClick={() => reEnableScheduling()} key="reenable-scheduling">
        <span
          className={enabled ? '' : 'sw-tooltip'}
          data-tooltip={enabled ? undefined : 'Scheduling can be re-enabled from the editor view'}
        >
          Re-enable Scheduling
        </span>
      </MenuItem>
    )
  }

  if (!scheduler && !(sessionStatus && attendedStates.includes(sessionStatus.status))) {
    menuItems.push(
      <MenuItem
        key="change-request"
        onClick={() => setDisplayEditCellModalFor({ userId: user.id, moduleKey: moduleKey })}
      >
        Change Request
      </MenuItem>
    )
  }

  const menu = editable && menuItems.length > 0 && (
    <ControlledMenu
      {...menuProps}
      anchorPoint={anchorPoint}
      onClose={() => toggleMenu(false)}
      onItemClick={event => event.syntheticEvent.stopPropagation()}
      menuClassName="font-semibold"
    >
      {menuItems}
    </ControlledMenu>
  )

  return (
    <td
      id={id}
      className={className}
      onClick={onClick}
      data-tooltip={tooltip}
      onContextMenu={menu ? showContextMenu : undefined}
    >
      {children}
      {menu && <TableMenu>{menu}</TableMenu>}
      {shareNotesModalOpen && (
        <LearnerModuleAccessModal
          user_id={user.id}
          moduleKey={moduleKey}
          onComplete={data => {
            updateSharedNotes(data)
            setShareNotesModalOpen(false)
          }}
          user_first_name={user.name}
          onCancel={() => setShareNotesModalOpen(false)}
        />
      )}
      {doNotScheduleModalOpen && (
        <DoNotScheduleModal
          user={user}
          sessionTitle={sessionTitle}
          onClose={() => setDoNotScheduleModalOpen(false)}
          onConfirm={reason => {
            updateDoNotSchedule({ doNotSchedule: true, reason, userId: user.id, moduleKey })
            setDoNotScheduleModalOpen(false)
          }}
        />
      )}
    </td>
  )
}
export default PlannedSessionRow
