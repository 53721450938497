import { application } from './application'
import RevealController from '@skiller-whale/style/controllers/reveal'
import OverflowController from '@skiller-whale/style/controllers/overflow'
import UrlFragmentController from './url_fragment_controller'
import CoachMessagingModalController from './coach_messaging_modal_controller'
import TabGroupController from './tab_group_controller'

application.register('overflow', OverflowController)
application.register('reveal', RevealController)
application.register('url-fragment', UrlFragmentController)
application.register('coach-messaging-modal', CoachMessagingModalController)
application.register('tab-group', TabGroupController)
