import { ReactElement, useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@skiller-whale/style/font_awesome_config'

import SessionEditor from './session_editor/session_editor'
import PlanMetadataEditor from './components/plan_metadata_editor'
import Scheduling from './components/scheduling'
import PlanContext from './components/plan_context'
import Toggle from '../library/toggle'

import { Timeslot, TrainingPlanPageProps } from '../generated_types/training_plan'
import { TrainingPlan, TrainingPlanStatus, AvailableTrainer } from './types'
import { getIndefinitelyPausedUserIds } from './utils'
import { SchedulerContextProvider } from './session_editor/scheduler_panel/use_scheduler'
import { PresenceProvider } from './components/use_presence'
import CoachingPlanChannelProvider from './components/use_coaching_plan_channel'

const TrainingPlanEditor = ({
  persisted_plan: persistedPlan,
  training_plan: initialPlan,
  modules: initialModules,
  active_module_keys,
  deprecated_module_keys,
  editable: initialEditable,
  user_ids_added_from_assessment: userIdsAddedFromAssessment,
  moved_training_plan_user_ids: movedTrainingPlanUserIds,
  snoozes,
  trainers,
  training_plan_trainers: initialTrainingPlanTrainers,
  comments,
  schedulers,
  default_to_scheduler: defaultToScheduler = true,
  unresolved_change_requests: unresolvedChangeRequests,
  include_change_request_response: showCRResponse,
  available_learners
}: Omit<TrainingPlanPageProps, 'active_module_metadata'> & { active_module_keys: string[] }) => {
  const { id: trainingPlanId } = initialPlan

  const [planMetadata, setPlanMetadata] = useState({
    draft: initialPlan.draft,
    status: initialPlan.status,
    name: initialPlan.name
  })

  const known_module_keys = active_module_keys.concat(deprecated_module_keys)
  const [editingMetadata, setEditingMetadata] = useState(false)
  const [modules, setModules] = useState(initialModules)
  const { draft, status, name } = planMetadata

  const [learners, setLearners] = useState(initialPlan.learners)
  const [editable, setEditable] = useState(initialEditable)
  const [schedulerId, setSchedulerId] = useState(initialPlan.scheduler_id)
  const [showScheduler, setShowScheduler] = useState(defaultToScheduler)
  const [trainingPlanTrainers, setTrainingPlanTrainers] = useState(initialTrainingPlanTrainers || [])
  const [timeslots, setTimeslots] = useState(initialPlan.timeslots)
  const [frequencyInWeeks, setFrequencyInWeeks] = useState(initialPlan.frequency_in_weeks)
  const [inviteOptional, setInviteOptional] = useState(initialPlan.invite_optional)
  const [autoStartHostedEnvironments, setAutoStartHostedEnvironments] = useState(
    initialPlan.auto_start_hosted_environments
  )
  const [assignedTrainers, setAssignedTrainers] = useState<AvailableTrainer[]>([])
  const timeslotsByCoach: Record<number, Timeslot[]> = Object.fromEntries(
    trainingPlanTrainers.map(tpt => {
      const timeslot_ids = tpt.coach_timeslots.map(t => t.timeslot_id)
      return [tpt.user_id, timeslots.filter(timeslot => timeslot_ids.includes(timeslot.id))]
    })
  )
  const trainingPlan: TrainingPlan = {
    ...initialPlan,
    ...planMetadata,
    scheduler_id: schedulerId,
    frequency_in_weeks: frequencyInWeeks,
    auto_start_hosted_environments: autoStartHostedEnvironments,
    invite_optional: inviteOptional,
    learners,
    timeslots
  }

  // When trainingPlanTrainers changes, update assignedTrainers with richer data from trainers
  useEffect(() => {
    if (!trainers) return
    const assignTrainers: AvailableTrainer[] = []
    trainingPlanTrainers.forEach(planTrainer => {
      const newAssignTrainer = Object.values(trainers).find(trainer => trainer.id === planTrainer.user_id)
      newAssignTrainer && assignTrainers.push(newAssignTrainer)
    })
    setAssignedTrainers(assignTrainers)
  }, [trainers, trainingPlanTrainers])

  const defaultTrainerId = trainingPlanTrainers.length === 1 ? trainingPlanTrainers[0].user_id : undefined

  const previewMode = trainingPlanId == null

  const draftModeWarning =
    draft && trainingPlanId ? (
      <div className="sw-toast warning">
        This Coaching Plan is marked as a draft and is <strong>not visible to managers or learners</strong>.
      </div>
    ) : null

  const schedulingDataAvailable = trainers && trainingPlanTrainers && 'scheduling_notes' in trainingPlan && schedulers

  const statusWarning =
    status == TrainingPlanStatus.Inactive ? (
      <div className="sw-toast warning">
        This Coaching Plan is marked as <strong>inactive</strong>
      </div>
    ) : null

  let changeRequestWarning: ReactElement | null = null
  if (unresolvedChangeRequests && unresolvedChangeRequests.count > 0) {
    const text =
      unresolvedChangeRequests.count == 1 ? (
        <>
          There is <strong>1 unactioned</strong> change request associated with this plan.
        </>
      ) : (
        <>
          There are <strong>{unresolvedChangeRequests.count} unactioned</strong> change requests associated with this
          plan.
        </>
      )
    changeRequestWarning = (
      <div className="flex gap-4 items-center my-4">
        <div className="sw-toast warning grow m-0">{text}</div>
        <a className="sw-btn btn-primary is-warning" target="top" href={unresolvedChangeRequests.view_url}>
          View
          <FontAwesomeIcon icon={['fas', 'chevron-right']} />
        </a>
      </div>
    )
  }

  const viewAsManager = initialEditable ? (
    <Toggle checked={!editable} onChange={newValue => setEditable(!newValue)} label="View as Manager" />
  ) : null

  const indefinitelyPausedUserIds = getIndefinitelyPausedUserIds(snoozes)

  const channelPlanIds = useMemo(() => (trainingPlanId ? [trainingPlanId] : null), [trainingPlanId])

  return (
    <div className="training-plan-edit">
      <CoachingPlanChannelProvider planIds={channelPlanIds}>
        <PresenceProvider scheduler={showScheduler}>
          {!previewMode && (
            <>
              <div className="flex items-center">
                <h1 className="grow mb-2">Coaching Plan</h1>
                {viewAsManager}
              </div>
              <div className="flex items-center">
                <h2 className="text-primary my-3 grow">{name}</h2>
                {editable && (
                  <button onClick={() => setEditingMetadata(true)} className="sw-btn btn-tertiary">
                    <FontAwesomeIcon icon={['fas', 'pencil']} />
                    Plan Settings
                  </button>
                )}
              </div>
            </>
          )}
          {editable && !previewMode && editingMetadata && schedulers && (
            <PlanMetadataEditor
              trainingPlanId={trainingPlanId}
              data={planMetadata}
              onUpdated={setPlanMetadata}
              onClose={() => setEditingMetadata(false)}
            />
          )}
          {(draftModeWarning || statusWarning || changeRequestWarning) && (
            <div className="padded-content">
              {draftModeWarning}
              {statusWarning}
              {changeRequestWarning}
            </div>
          )}
          <SchedulerContextProvider
            trainingPlanIds={trainingPlanId}
            learners={learners}
            assignedTrainers={assignedTrainers}
            available_learners={available_learners}
            timeslotsByCoach={timeslotsByCoach}
            modules={modules}
          >
            {editable && schedulingDataAvailable && (
              <Scheduling
                trainingPlan={trainingPlan}
                trainers={trainers}
                trainingPlanTrainers={trainingPlanTrainers}
                setTrainingPlanTrainers={setTrainingPlanTrainers}
                setTimeslots={setTimeslots}
                setSchedulerId={setSchedulerId}
                setFrequencyInWeeks={setFrequencyInWeeks}
                setAutoStartHostedEnvironments={setAutoStartHostedEnvironments}
                setInviteOptional={setInviteOptional}
                pauses={snoozes}
                schedulers={schedulers}
                availableLearners={available_learners}
              />
            )}
            <SessionEditor
              persistedPlan={persistedPlan}
              trainingPlan={trainingPlan}
              editable={editable}
              availableLearners={available_learners}
              setLearners={setLearners}
              modules={modules}
              known_module_keys={known_module_keys}
              setModules={setModules}
              initialUserIdsAddedFromAssessment={userIdsAddedFromAssessment}
              initialMovedTrainingPlanUserIds={movedTrainingPlanUserIds}
              indefinitelyPausedUserIds={indefinitelyPausedUserIds}
              defaultTrainerId={defaultTrainerId}
              showScheduler={showScheduler}
              setShowScheduler={setShowScheduler}
              showCRResponse={showCRResponse}
            />
          </SchedulerContextProvider>
          {editable && !previewMode && (
            <PlanContext trainingPlanId={trainingPlanId} trainingPlan={trainingPlan} comments={comments} />
          )}
        </PresenceProvider>
      </CoachingPlanChannelProvider>
    </div>
  )
}
export default TrainingPlanEditor
