import { ReactNode } from 'react'
import { PlanMetadata } from '../../generated_types/training_plan'
import HeaderCell from '../session_editor/header_cell'
import { UserFilter, TrainingPlanContext } from '../types'
import { TrainingPlanPath } from '../../utils/api/paths'

interface TrainingPlanHeaderProps extends Pick<TrainingPlanContext, 'available_learners'> {
  indefinitePauses: UserFilter
  trainingPlans: PlanMetadata[]
}

const MultiPlanHeader = ({ trainingPlans, available_learners, indefinitePauses }: TrainingPlanHeaderProps) => {
  const planCells: ReactNode[] = []
  const userCells = trainingPlans.flatMap((trainingPlan, planIndex) => {
    const renderableUsers = trainingPlan.learners.filter(learner => {
      const userIndefinitelyPaused = indefinitePauses.userIds.includes(learner.user_id)
      if (userIndefinitelyPaused && !indefinitePauses.show) return false
      return true
    })

    if (renderableUsers.length == 0) {
      return null
    }

    const headerCells = renderableUsers.map((learner, index) => (
      <HeaderCell
        user={available_learners[learner.user_id]}
        learner={learner}
        key={learner.user_id}
        index={index}
        updateOrdering={noOp}
        editable={false}
        markedForDestruction={false}
        toggleUser={noOp}
        planHasChanges={false}
        userMoved={false}
        userIndefinitelyPaused={indefinitePauses.userIds.includes(learner.user_id)}
      />
    ))

    let planCellSpan = renderableUsers.length

    if (planIndex < trainingPlans.length - 1) {
      // if there are still more plans
      headerCells.push(<td key={`divider-${planIndex}`} className="px-2" />)
      planCellSpan += 1
    }
    planCells.push(
      <th key={trainingPlan.id} colSpan={planCellSpan} className={trainingPlan.draft ? 'striped-warning-border' : ''}>
        <a href={`/plans/${trainingPlan.id}` satisfies TrainingPlanPath} rel="noreferrer" target="_blank">
          {trainingPlan.name || trainingPlan.id}
          {trainingPlan.draft ? ' (Draft)' : ''}
        </a>
      </th>
    )
    return headerCells
  })

  return (
    <thead>
      <tr>
        <th colSpan={2} className="sticky relative z-30 left-0" />
        {planCells}
      </tr>
      <tr>
        <th className={`controls-header`}>Plan Modules</th>
        <th className="estimate-mins-header">Estimated Minutes</th>
        {userCells}
      </tr>
    </thead>
  )
}
const noOp = () => {}

export default MultiPlanHeader
